import styled from '@emotion/styled'

import { lightTheme } from '@/theme'

import { TEST_IDS } from './test/constants'

export type PropTypes = {
  count: string | number
}

const Badge = styled.div`
  position: absolute;
  top: -0.7rem;
  right: -0.7rem;
  background-color: ${lightTheme.colors.danger};
  color: white;
  border-radius: 1.5rem;
  padding: 0.3rem;
  font-size: 1rem;
  box-sizing: content-box;
  height: 1.6rem;
  width: 1.6rem;
  line-height: 1.6rem;
`

function BadgeComponent({count = 0}: PropTypes) {

  return (
    <Badge data-e2eid={TEST_IDS.BADGE}>{count}</Badge>
  )
}

export default BadgeComponent
