import styled from '@emotion/styled'

import Spinner from 'microcomponents/spinner'

import { TEST_IDS } from './test/constants'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default function SpinnerComponent({ pixelSize }: { pixelSize?: number }) {
  return (
    <Container data-e2eid={TEST_IDS.SPINNER}>
      <Spinner pixelSize={pixelSize} />
    </Container>
  )
}
